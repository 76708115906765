/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    br: "br"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ResponsiveImg, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!ResponsiveImg) _missingMdxReference("ResponsiveImg", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/'], ['en', 'https://www.hear.com/hearing-aids/'], ['en-US', 'https://www.hear.com/hearing-aids/'], ['en-IN', 'https://www.hear.com/in/hearing-aids/'], ['en-ZA', 'https://www.hear.com/za/hearing-aid-types/'], ['en-MY', 'https://www.hear.com/my/hearing-aids/'], ['ko-KR', 'https://www.hear.com/kr/hearing-aid-shape'], ['en-CA', 'https://ca.hear.com/hearing-aids/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "modern-hearing-aids-for-a-modern-world",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modern-hearing-aids-for-a-modern-world",
    "aria-label": "modern hearing aids for a modern world permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modern Hearing Aids for a Modern World"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are a great, non-invasive ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "solution to hearing loss."), " Many users report that ever since they started wearing hearing aids, their quality of life has dramatically improved. In fact, many of them wish they had started wearing hearing aids sooner. The benefits are felt in every area of a person’s life. For example, hearing aids strengthen relationships with friends and co-workers because it improves communication. In addition, many studies show that hearing well can reduce stress, increase energy, and boost your mental alertness. In a world inundated with sounds, hearing aids can help you hear what you want to hear, while automatically reducing background noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all the advancements in technology, it’s no surprise that there are many different hearing aid models. However, what all modern hearing aids have in common is their unbelievably small size. Both in-the-ear and behind-the-ear devices are the size of a coffee bean. In terms of technology, the latest hearing aids are basically mini super computers that use advanced algorithms to process sounds and deliver crystal-clear sound. Finding the right hearing aid depends on a number of factors. In this page, you will learn more about the different models, manufacturers, and more. If you have any additional questions, you can contact a hearing aid expert from hear.com for a free, unbiased consultation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "choosing-the-right-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#choosing-the-right-hearing-aid",
    "aria-label": "choosing the right hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Choosing the Right Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are not one-size-fits-all. You need to choose devices that meet your needs and fit seamlessly into your lifestyle. For example, a device with basic functions may be suitable for you depending on your daily activities. But if you’re someone who is very active, a premium technology level may provide you with the best listening experience. To help you determine which technology level is right for you, take a look at the chart below."), "\n", React.createElement(ResponsiveImg, {
    mobileSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aid-choices.jpg",
    desktopSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aid-choices-desk.jpg",
    alt: "Graph of different hearing aids usage"
  }), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-different-hearing-aid-technologies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-different-hearing-aid-technologies",
    "aria-label": "what are the different hearing aid technologies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Are the Different Hearing Aid Technologies"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "premium-technology-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#premium-technology-hearing-aids",
    "aria-label": "premium technology hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Premium Technology Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-x.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for hearing aids with all the bells and whistles, then look no further than Premium technology. These devices offer more advanced versions of features found in the Basic and Mid-Range levels, making them the best solution for both active and sedentary lifestyles. Whether you spend your days indoors or outdoors, you will enjoy natural and comfortable listening. They deliver HD sound quality, directional microphones, digital noise/feedback reduction and self learning. Best of all, Premium level technology uses Bluetooth to wirelessly stream music, phone calls and more directly to your hearing aids. A premium hearing aid costs between $2,699 to $3,500.")), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "mid-range-technology-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mid-range-technology-hearing-aids",
    "aria-label": "mid range technology hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mid-Range Technology Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Starkey-Muse-receiver-in-canal-micro-hearing-aid.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids with this technology level are great for people who live a more active lifestyle. These devices provide good sound quality, even in complex listening situations. Examples of places where you can benefit from wearing Mid-Range level devices include large auditoriums, family get-togethers, sporting events and restaurants. Mid-Range level technology gives you the ability to change listening programs and improve listening comfort in any setting. In addition, many of the features are automated, which removes the constant need to manually adjust the settings. Some of these features include adaptive directional microphones and automatic noise reduction. A mid-range device costs between $1,999 and $2,699.")), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "basic-technology-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-technology-hearing-aids",
    "aria-label": "basic technology hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic Technology Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-Enya-Ca.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Basic level technology is a good solution for individuals who spend a lot of time at home and enjoy having one-on-one conversations. Devices with basic technology provide good amplification and feedback cancellation in quiet environments. Their basic features allow you to hear the TV at normal volume and understand your conversation partner clearly in small group settings. A basic hearing aid cost from $1,249 to $1,999.")), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-styles",
    "aria-label": "hearing aid styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Styles"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-ITE.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "ite-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ite-hearing-aids",
    "aria-label": "ite hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ITE Hearing Aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Customizable & virtually invisible: Since it fits directly inside the ear canal, in-the-ear hearing aids are virtually invisible. Due to their small size, ITE devices are best suited for those with mild to moderate hearing loss. Additionally, whether or not you can use an ITE device depends on the amount of room in your auditory canal. ", React.createElement(_components.a, {
    href: "/about-us/network",
    className: "c-md-a"
  }, "(Our local Partner Provider"), " will conduct a comprehensive hearing evaluation in order to fit you with the right style of hearing aid.)")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-BTE.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "bte-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bte-hearing-aids",
    "aria-label": "bte hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "BTE Hearing Aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "High-performance & discreet design: In their smallest form, behind-the-ear hearing aids are about the size of a coffee bean, featuring a sound tube with a customized ear mold or external receiver placed in the ear canal. BTEs are suitable for mild to severe hearing loss. This type of hearing aid is generally available in various sizes and styles depending on your preferred performance and ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "battery strength."))), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-manufacturers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-manufacturers",
    "aria-label": "hearing aid manufacturers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Manufacturers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aid/brands",
    className: "c-md-a"
  }, "Some of the top hearing aid manufacturers"), " who are leading the way in innovative hearing loss solutions include Signia, Bernafon, Starkey, ReSound, and more. These companies are continually developing new technology, more advanced features, and greater ease of use with wireless connectivity. All these new and exciting things are guaranteed to improve your overall listening experience. At hear.com, we carry the latest devices from the leading manufacturers, so contact us today to learn more about how you can test them risk free."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-signia.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "Signia")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Piscataway, NJ\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Signia is the latest hearing aid brand, co-branded with Siemens, that uses the new primax technology to reduce listening effort in any environment. Signia primax builds on the successful binax platform by providing a true binaural experience that redefines ease of listening.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-widex.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/",
    className: "c-md-a"
  }, "Widex")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1956"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Lynge, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Restoring natural hearing is paramount for Widex. To this end, the Danish manufacturer creates innovative, high quality hearing aids that bring newfound joy to the lives of people with hearing loss. The world’s first fully digital hearing aid is a testament to Widex’s spirit of innovation.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-resound.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/",
    className: "c-md-a"
  }, "ReSound")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1943"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Ballerup, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Using innovative hearing solutions, ReSound guides people of all ages back into the world of sound. Since 1943, this Danish company has been developing high-quality hearing devices that allow their wearers to forget they have hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-bernafon.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/bernafon/",
    className: "c-md-a"
  }, "Discover Bernafon"), "\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Hearing and understanding are basic needs of every individual. With Swiss innovation and high-quality products, Bernafon is uniquely situated to help people with hearing loss regain their quality of life and improve communication with family and friends. Bernafon hearing aids use state-of-the-art hearing technology to compensate for hearing loss while providing additional personal benefits like wireless connectivity for hands-free use of your mobile phone.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-starkey.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/",
    className: "c-md-a"
  }, "Starkey")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Eden Prairie, MN\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Starkey Hearing Technologies is much more than the hearing aids they produce. They are in the business of connecting people and changing lives. They believe that being able to hear the world and the people around us is as essential to the human experience as breathing.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-unitron.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/",
    className: "c-md-a"
  }, "Unitron")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Kitchener, ON, Canada\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The ability to hear greatly affects one’s quality of life. Unitron’s state-of-the-art hearing aids help restore a higher quality of life for those with hearing loss. Unitron aims to provide uncompromising speech comprehension and natural hearing.")), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Silk-still-life7.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Over the last few years, cell phones and computers have gotten smaller, thinner, and faster. But did you know that today’s hearing aids have also experienced the same type of innovations? In fact, they are like tiny, high-performance computers that are virtually invisible. Gone are the days of bulky hearing systems that were visible from 20 feet away. Now they are as small as a coffee bean.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aids of the past were highly visible and only made sounds louder. These new hearing systems use advanced algorithms to pick up voices and reduce background noise automatically. This mean you can sit in a restaurant calmly and enjoy great conversations with your friends while the hearing aids work seamlessly to reduce background noise. Another benefit of hearing aids is that they can be programmed to adapt to your current listening environment automatically and in real time. Digital technology also allows for the creation of different customized programs to address your personal hearing needs."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-hearing-aids-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-hearing-aids-work",
    "aria-label": "how do hearing aids work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do hearing aids work?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Signia-Silk-TB530.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "To put it simply: Hearing aids supplement our hearing. But how exactly do they work? Firstly, hearing aids are designed to receive sounds from the outside world, just like a healthy ear would do. To do so, they include microphones as part of the design, which take sounds and convert them into vibrations, and then into electrical impulses."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, at this stage these signals are often too weak, and need to be amplified before they can be converted into something wearers can actually hear. Amplifiers inside the hearing aid casing do the job, turning even very quiet noises into signals that can be turned into audible noise.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The final part of the hearing aid is (usually) the speaker, which takes these amplified signals and produces noise. The precise sound level can almost always be finely calibrated, depending on the environment and the wearer’s degree of deafness. Many hearing aids can also be calibrated to amplify high or low frequency sounds, again, depending on the user’s needs."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
